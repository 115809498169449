<template>
    <div class="about-aricle">
        <div class="boxtitle">
            <div class="box">
                <div class="len"></div>
                <span>相关阅读</span>
            </div>
            <div class="boxtitle-right" @click="goHome">
                <span>查看更多</span>
                <img src="../../static/icon/get-more.png">
            </div>
        </div>
        <div class="article-lists" v-if="articleLists.length > 0">
            <div class="article-item" v-for="(item , index) in articleLists" :key="item.id" @click="goDetails(index)">
                <div class="title-box">
                    <span class="time" style="font-size: 14px;">{{ item.creationTime.split('T')[0] }}</span>
                    <span class="time">{{ item.creationTime.split('T')[1].split(':')[0] + ':' +
                        item.creationTime.split('T')[1].split(':')[1] }}</span>
                    <span class="column"><span v-for="(el , elIdx) in item.tags" :key="elIdx">#{{ el }}</span></span>
                    <span class="title">{{ item.title }}</span>
                </div>
                <span class="content">{{ item.description }}</span>
                <div class="footer-box">
                    <span class="num">阅读 {{ item.pv }} · 评论 {{ item.cn }}</span>
                    <div class="is-look">
                        <img v-if="isUnlock" class="unlock-icon" src="../../static/icon/suo-icon.png">
                        <span v-if="isUnlock" class="unlock">解锁后阅读</span>
                        <span v-if="!isUnlock" class="go-details">查看详情</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getRelatedArticle } from '@/api/article.js'
export default {
    name: "about-aricle",
    data() {
        return {
            articleLists: [],//主文章列表
            isUnlock: false
        }
    },
    props: {
        articleId: {}
    },
    created() {
        this.getRelatedArticle()
    },
    watch: {
        // 监听路由对象，一旦变化，重新获取数据
        '$route'() {
            this.getRelatedArticle()
        }
    },
    methods: {
        /**
         * 获取相关文章
         */
        getRelatedArticle() {
            getRelatedArticle(this.articleId).then(res => {
                this.articleLists = res
            })
        },
        /**
         * 跳转首页
         */
        goHome() {
            this.$router.push({ path: '/' });
        },
        /**
         * 查看详情
         */
         goDetails(index) {
            this.$router.push({ path: "/details", query: { id: this.articleLists[index].id } });
        }
    }
}
</script>

<style scoped>
.about-aricle {
    background-color: #ffffff;
}

.boxtitle {
    background-color: #ffffff;
    width: 848px;
    padding: 24px 0;
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px #CDD2D9;
}

.box {
    flex: 1;
    display: flex;
    align-items: center;
}

.box span {
    color: #020C17;
    font-size: 18px;
}

.len {
    width: 4px;
    height: 20px;
    background-color: #DD0722;
    margin-right: 6px;
}

.boxtitle-right {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.boxtitle-right span {
    font-size: 16px;
    color: #DD0722;
    margin-right: 6px;
}

.boxtitle-right img {
    width: 14px;
    height: 10px;
}

.article-lists {
    width: 888px;
    background-color: #ffffff;
}

.article-item {
    width: 848px;
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: 1px solid #CDD2D9;
    cursor: pointer;
}

.article-item:last-child {
    border: none;
}

.article-item .title-box {
    display: flex;
    align-items: center;
}

.article-item .title-box span {
    font-size: 18px;
    margin-right: 8px;
    font-weight: bold;
}

.article-item .title-box .time {
    color: #E2450A;
}

.article-item .title-box .column {
    color: #4181B8;
}

.article-item .title-box .title {
    color: #06121E;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.article-item .content {
    display: block;
    margin-top: 16px;
    font-size: 16px;
    color: #06121E;
}

.article-item .footer-box {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.article-item .footer-box .num {
    font-size: 12px;
    color: #BCBCBC;
}

.article-item .footer-box .is-look {
    display: flex;
    align-items: center;
}

.article-item .footer-box .is-look .go-details {
    color: #BCBCBC;
    font-size: 12px;
}

.article-item .footer-box .is-look .unlock-icon {
    margin-right: 6px;
    width: 12px;
    height: 12px;
}

.article-item .footer-box .is-look .unlock {
    color: #E2450A;
    font-size: 12px;
}
</style>
