import request from "@/utils/request";

/**
 * 获取置顶文章列表
 * @param {*} data 
 * @param {String} top || 数量
 * @returns 
 */
export const getTopArticle = (data) => {
    return request({
        url: "/api/app/articleApp/top",
        method: "get",
        params: data,
    });
};

/**
 * 获取首页列表
 */
export const getHomeArticle = (data) => {
    return request({
        url: "/api/app/articleApp/query",
        method: "post",
        data: data,
    });
};

/**
 * 获取文章详情
 * @param {*} data 
 * @param {String} id || 文章id
 * @returns 
 */
export const getArticleDetails = (id) => {
    return request({
        url: "/api/app/articleApp/" + id,
        method: "get"
    });
};


/**
 * 获取相关阅读
 * @param {*} data 
 * @param {String} id || 文章id
 * @returns 
 */
export const getRelatedArticle = (id) => {
    return request({
        url: "/api/app/articleApp/related/" + id,
        method: "get"
    });
};

