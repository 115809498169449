<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <div class="main-left">
                    <div class="article">
                        <div class="article-title">
                            <span class="title-column" v-for="item in details.tags" :key="item">#{{ item }}</span>
                            <span class="title">{{ details.title }}</span>
                        </div>
                        <span class="time" v-if="details.creationTime">{{ details.creationTime.split('T')[0] + ' ' +
                            details.creationTime.split('T')[1].split('.')[0] }}</span>

                        <div v-if="details.needBuy">
                            <div class="description">{{ details.description }}</div>
                            <div class="jiesuo">
                                <span v-if="!details.firstBuy">单篇付费<span class="jiesuo-price">￥{{ details.price
                                }}</span>可解锁全文</span>
                                <span v-else>单篇付费 首次购买<span class="jiesuo-price">￥{{ details.discountPrice }}</span>原价<span
                                        class="yuanjia">￥{{ details.price }}</span>仅限首次</span>
                                <div class="jiesuo-btn" @click="jiesuo">立即解锁</div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="description">{{ details.description }}</div>
                            <div class="jiesuo" style="margin-top: 20px;" v-if="!token">
                                <div class="jiesuo-btn" style="width: 140px;" @click="isShowLogin = true">登录查看全文</div>
                            </div>
                        </div>
                       
                        <div v-if="!details.needBuy" class="rich-text" v-html="details.content"></div>
                        <span class="shengming">青峰见财讯声明：文章内容仅供参考，不构成投资建议。投资者据此操作，风险自担。</span>
                        <img @click="goDingyue" src="../../static/images/banner.png" class="banner">
                        <span class="look-num">阅读 {{ details.pv }} <span style="margin-left: 8px;">{{ details.buyCount
                        }}人已解锁</span></span>
                        <div class="article-footer">
                            <div class="comment">
                                <input v-model="commentText" type="text" placeholder="发表评论">
                                <div class="put-comment" @click="putComment">发表</div>
                            </div>
                            <img @click="addCollect" v-if="details.isCollection" class="collection-icon"
                                src="../../static/icon/collection.png">
                            <img @click="addCollect" v-else class="collection-icon"
                                src="../../static/icon/collection-no.png">
                            <img class="comment-icon" src="../../static/icon/comment.png">
                            <span class="comment-span">{{ details.commentCount }}</span>
                            <img @click="shareBox = true" class="share" src="../../static/icon/share.png">
                        </div>
                    </div>
                    <div class="comment-box" v-if="token && commentLists.length > 0">
                        <div class="comment-title">
                            <div class="len"></div>
                            <span>全部评论</span>
                        </div>
                        <div class="comment-lists">
                            <div class="comment-item" v-for="(item, index) in commentLists" :key="item.id">
                                <img class="comment-avatarUrl" v-if="item.author.avatar" :src="item.author.avatar">
                                <div class="comment-item-center">
                                    <span class="comment-item-center-username">{{ item.author.username }}</span>
                                    <span class="comment-item-center-content">{{ item.content }}</span>
                                    <span class="comment-item-center-time">{{ item.creationTime.split('T')[0] }}</span>
                                </div>
                                <div class="comment-item-right" v-if="userId == item.author.userId"
                                    @click="deleteComment(item.id, index)">
                                    <img src="../../static/icon/delete-icon.png">
                                    <span>删除评论</span>
                                </div>
                            </div>
                            <getMore @getMore="getMore" :state="getMoreState"></getMore>
                        </div>
                    </div>
                    <aboutArticle :articleId="id"></aboutArticle>
                </div>
                <div class="main-right">
                    <homeRight @loginOpen="isShowLogin = true"></homeRight>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
        <payBox :isShow="isShowPay" :price="details.firstBuy ? details.discountPrice : details.price" @close="isShowPay = false"
            :payType="2" :payId="id" :orderInfo="orderInfo"></payBox>
        <div class="wx-share" v-if="shareBox">
            <div class="share-box">
                <img class="close-icon" @click="shareBox = false" src="../../static/icon/close-icon.png">
                <span class="share-title">分享到微信朋友圈</span>
                <createCode :text="codeUrl"></createCode>
                <span class="share-tips">打开微信，点击底部的“发现”，使用 “扫一扫” 即可将网页分享到我的朋友圈</span>
            </div>
        </div>
        <loginBox :isShow="isShowLogin" @close="isShowLogin = false"></loginBox>
        <bindBox :show="isShowBindBox" @close="isShowBindBox = false" :bindCode="bindCode"></bindBox>
    </div>
</template>

<script>
import loginBox from '@/components/loginBox.vue'
import bindBox from '@/components/bindBox.vue'
import createCode from '@/components/createCode.vue'
import { getStorage } from "@/utils/auth";
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import homeRight from '@/components/homeRight.vue'
import aboutArticle from '@/components/aboutArticle.vue'
import payBox from '@/components/payBox.vue'
import getMore from '@/components/getMore.vue'
import { getArticleDetails } from '@/api/article.js'
import { addCollect, cancelCollect } from '@/api/collect.js'
import { getCommentLists, addComment, deleteComment } from '@/api/comment.js'
export default {
    name: "articleDetails",
    components: {
        pageHeader,
        pageFooter,
        homeRight,
        aboutArticle,
        payBox,
        getMore,
        createCode,
        loginBox,
        bindBox
    },
    data() {
        return {
            id: '',
            details: {},//文章详情
            commentText: '',//评论内容
            isShowPay: false,//是否展示扫码支付框
            imgCode: '',//支付二维码地址
            payCode: '',//支付code
            token: '',
            getMoreState: 'getMore',
            page: 0,
            pageSize: 10,
            total: null,
            commentLists: [],//评论列表
            userId: '',//用户id
            shareBox: false,//微信分享是否显示
            codeUrl: '',//微信分享地址
            isShowLogin: false,//是否展示登录框
            orderInfo: {},
            bindCode: '',//绑定码
            isShowBindBox: false,//绑定手机号弹窗是否展示
        }
    },
    created() {
        this.codeUrl = window.location.href;
        this.id = this.$route.query.id
        this.token = this.getToken()
        // if (this.$store.state.bindCode && !this.getToken()) {
        //     this.bindCode = this.$store.state.bindCode
        if (this.$route.query.bindCode && !this.getToken()) {
            this.bindCode = this.$route.query.bindCode
            this.isShowBindBox = true
        }
        this.getDetails()
        if (this.token) {
            this.getCommentLists()
        }
        this.userId = getStorage('userId')
    },
    watch: {
        // 监听路由对象，一旦变化，重新获取数据
        '$route'() {
            this.$router.push({ path: "/refresh" });
        }
    },
    methods: {
        /**
         * 删除评论
         */
        deleteComment(id, index) {
            deleteComment([id]).then(() => {
                this.commentLists.splice(index, 1)
            })
        },
        /**
         * 解锁
         */
        jiesuo() {
            if (!this.token) {
                this.isShowLogin = true
                return
            }
            this.orderInfo = {
                title: this.details.title,
                tags: this.details.tags,
                description: this.details.description
            }
            this.isShowPay = true
        },
        /**
         * 获取详情
         */
        getDetails() {
            getArticleDetails(this.id).then(res => {
                this.details = res
            })
        },
        /**
        * 加载更多评论
        */
        getMore() {
            this.getMoreState = 'loading'
            setTimeout(() => {
                this.getCommentLists()
            }, 800)
        },
        /**
         * 获取评论列表
         */
        getCommentLists() {
            if (this.total === this.commentLists.length) {
                this.getMoreState = 'noMore'
                return
            }
            this.page++
            getCommentLists({
                page: this.page,
                pageSize: this.pageSize,
                filter: {
                    articleId: this.id
                }
            }).then(res => {
                this.commentLists = this.commentLists.concat(res.items)
                this.total = res.totalCount
                if (res.items.length < this.pageSize) {
                    this.getMoreState = 'noMore'
                } else {
                    this.getMoreState = 'getMore'
                }
            })
        },
        /**
         * 发表评论
         */
        putComment() {
            if (!this.token) {
                this.isShowLogin = true
                return
            }
            if (!this.commentText) {
                this.$message.error('请输入评论内容');
                return
            }
            addComment({
                articleId: this.id,
                content: this.commentText
            }).then(() => {
                this.commentText = ''
                this.$message.success('发表成功');
                this.page = 1
                this.total = null

                getCommentLists({
                    page: this.page,
                    pageSize: this.pageSize,
                    filter: {
                        articleId: this.id
                    }
                }).then(res => {
                    this.commentLists = res.items
                    this.total = res.totalCount
                    if (res.items.length < this.pageSize) {
                        this.getMoreState = 'noMore'
                    } else {
                        this.getMoreState = 'getMore'
                    }
                })
            })
        },
        /**
         * 收藏取消收藏操作
         */
        addCollect() {
            if (!this.token) {
                this.isShowLogin = true
                return
            }
            if (this.details.isCollection) {
                cancelCollect({
                    id: this.id,
                    type: 4
                }).then(() => {
                    this.details.isCollection = false
                    this.$message.success('取消收藏成功');
                })
            } else {
                addCollect({
                    ids: [this.id],
                    type: 4
                }).then(() => {
                    this.details.isCollection = true
                    this.$message.success('收藏成功');
                })
            }
        },
        /**
         * 跳转订阅页面
         */
        goDingyue() {
            this.$router.push({ path: '/subscription' })
        }
    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.main-left {
    width: 888px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    background-color: #ffffff;
}

.article {
    width: 848px;
    margin: 0 20px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
}

.article-title {
    width: 848px;
    display: flex;
    align-items: flex-start;
}

.article-title span {
    font-weight: bold;
    font-size: 22px;
}

.article-title .title-column {
    color: #4181B8;
}

.article-title .title {
    color: #06121E;
    margin-left: 6px;
    flex: 1;
}

.article .time {
    color: #BCBCBC;
    font-size: 14px;
    margin-top: 16px;
}

.article .description {
    background-color: #f5f5f5;
    padding: 20px;
    margin-top: 20px;
    line-height: 1.6;
    border-radius: 8px;
}

.rich-text {
    margin-top: 16px;
    color: #06121E;
    font-size: 16px;
    line-height: 30px;
}

.rich-text>>>* {
    max-width: 100%;
}

.shengming {
    color: #aca8a8 !important;
    font-size: 14px !important;
    margin-top: 16px;
}

.banner {
    width: 848px;
    margin-top: 30px;
    cursor: pointer;
}

.jiesuo {
    width: 848px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 38px;
}

.jiesuo span {
    color: #666666;
    font-size: 16px;
}

.jiesuo span .jiesuo-price {
    color: #DD0722;
    font-size: 22px;
    font-weight: 14px;
    margin: 0 14px;
}

.jiesuo span .yuanjia {
    font-size: 16px;
    text-decoration: line-through;
    margin: 0 10px;
}

.jiesuo-btn {
    width: 99px;
    height: 33px;
    background: #DD0722;
    border-radius: 44px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.article .look-num {
    color: #979797;
    font-size: 14px;
    margin-top: 36px;
}

.article .look-num span {
    color: #979797;
    font-size: 14px;
    margin-left: 8px;
}

.article-footer {
    display: flex;
    align-items: center;
    margin-top: 32px;
    width: 848px;
}

.article-footer .comment {
    flex: 1;
    height: 38px;
    border-radius: 53px;
    background-color: #F5F5F5;
    padding: 0 16px;
    display: flex;
    align-items: center;
    padding-right: 0;
}

.article-footer .comment input {
    flex: 1;
    height: 38px;
    font-size: 16px;
    background-color: #F5F5F5;
    border: 0;
}

.article-footer .comment .put-comment {
    width: 99px;
    height: 38px;
    background: #DD0722;
    border-radius: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 16px;
}

.article-footer .collection-icon {
    width: 22px;
    height: 22px;
    margin-left: 28px;
    cursor: pointer;
}

.article-footer .comment-icon {
    margin-left: 26px;
    width: 17px;
    height: 17px;
}

.article-footer .comment-span {
    font-size: 16px;
    color: #979797;
    margin-left: 6px;
}

.article-footer .share {
    width: 23px;
    height: 21px;
    margin-left: 27px;
    cursor: pointer;
}

.comment-box {
    background-color: #ffffff;
    width: 848px;
    padding: 24px 0;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.comment-title {
    width: 848px;
    display: flex;
    align-items: center;
}

.comment-lists {
    width: 848px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.comment-item {
    width: 848px;
    border-bottom: 1px solid #CDD2D9;
    padding: 16px 0;
    display: flex;
    align-items: flex-start;
}

.comment-item:last-child {
    border-bottom: none;
}

.comment-item .comment-avatarUrl {
    width: 44px;
    height: 44px;
}

.comment-item .comment-item-center {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
}

.comment-item .comment-item-center .comment-item-center-username {
    color: #06121E;
    font-size: 14px;
}

.comment-item .comment-item-center .comment-item-center-content {
    color: #06121E;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 14px;
}

.comment-item .comment-item-center .comment-item-center-time {
    color: #979797;
    font-size: 12px;
}

.comment-item .comment-item-right {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.comment-item .comment-item-right img {
    width: 16px;
    height: 15px;
    margin-right: 6px;
}

.comment-item .comment-item-right span {
    color: #979797;
    font-size: 14px;
}

.box span {
    color: #020C17;
    font-size: 18px;
}

.len {
    width: 4px;
    height: 20px;
    background-color: #DD0722;
    margin-right: 6px;
}

.main-right {
    width: 296px;
}

.wx-share {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.share-box {
    width: 360px;
    height: 340px;
    background: #FFFFFF;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-radius: 10px;
}

.close-icon {
    position: absolute;
    right: 8px;
    top: 10px;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.share-title {
    font-size: 22px;
    margin-bottom: 20px;
}

.share-tips {
    font-size: 14px;
    color: #666666;
    margin-top: 20px;
}</style>
